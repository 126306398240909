import { Controller } from "@hotwired/stimulus";
const { validate, clean, format } = require("rut.js");
let storedAddress = [];
export default class extends Controller {
  static targets = [
    "shippingSelect",
    "field",
    "logisticTrainContainer",
    "warehouseButton",
    "logisticTrainButton",
    "shippingButton",
    "packaging",
    "shipping",
    "shippingAddress",
    "deliveryPrice",
    "packagingPrice",
    "rectificationPrice",
    "subtotal",
    "taxes",
    "total",
    "itemTotal",
    "itemUnit",
    "pickup",
    "validateForm"
  ];

  connect() {
    this.fieldTargets.find((f) => f.name == "rut").value = "";
    this.checkRectificationPrice();
    let packaging = document.getElementById("packaging").value;
    if(packaging === "no_packaging") {
      this.removePackagingPrice();
    } else {
      this.checkPackagingPrice();
    }
  }

  checkRut(event) {
    
    event.target.value = format(event.target.value);
    let fields = this.fieldTargets;
    if (validate(event.target.value)) {
      let cleanRut = clean(event.target.value.split("-")[0]);
      let url = `${window.location.href}/search_customer?rut=${cleanRut}`;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (data == null) {
            // Validate inside in case rut changed between the api call
            if (!validate(event.target.value)) {
              return this.disableForm();
            }
            this.hideLogisticTrain();
            this.shippingAddressTarget.classList.add("d-none");
            let addressSelect = this.shippingSelectTarget;
            addressSelect.innerHTML = "";
            let option = document.createElement("option");
            option.text = "-- Seleccione una dirección --";
            option.value = "";
            addressSelect.appendChild(option);
            option = document.createElement("option");
            option.text = "Usar la dirección de facturación";
            option.value = "billing_address";
            addressSelect.appendChild(option);
            option = document.createElement("option");
            option.text = "Añadir una nueva dirección";
            option.value = "new_address";
            addressSelect.appendChild(option);
            fields.find((f) => f.name == "business_name").value = "";
            fields.find((f) => f.name == "name").value = "";
            fields.find((f) => f.name == "email").value = "";
            fields.find((f) => f.name == "phone_number").value = "";
            fields.find((f) => f.name == "address").value = "";
            fields
              .find((f) => f.name == "business_name")
              .classList.remove("disabled");
            fields.find((f) => f.name == "name").classList.remove("disabled");
            fields.find((f) => f.name == "email").classList.remove("disabled");
            fields
              .find((f) => f.name == "commune")
              .classList.remove("disabled");
            fields
              .find((f) => f.name == "phone_number")
              .classList.remove("disabled");
            fields
              .find((f) => f.name == "address")
              .classList.remove("disabled");
            //fields.find( f => f.name == 'commit').disabled = false
          } else {
            let communeVal = null;
            this.checkCartPrice();
            document.querySelectorAll("#commune option").forEach((item) => {
              if (communeVal != null) {
                return;
              }
              if (
                item.textContent.trim().toUpperCase() ==
                data.commune
                  .normalize("NFD")
                  .replace(/\p{Diacritic}/gu, "")
                  .trim()
                  .toUpperCase()
              ) {
                communeVal = item.value;
              }
            });
            if (data.addresses.length > 0) {
              let addressSelect = this.shippingSelectTarget;
              addressSelect.innerHTML = "";
              let option = document.createElement("option");
              option.text = "-- Seleccione una dirección --";
              option.value = "";
              addressSelect.appendChild(option);
              option = document.createElement("option");
              option.text = "Usar la dirección de facturación";
              option.value = "billing_address";
              addressSelect.appendChild(option);
              option = document.createElement("option");
              option.text = "Añadir una nueva dirección";
              option.value = "new_address";
              addressSelect.appendChild(option);
              storedAddress = data.addresses;
              let index = 0;
              storedAddress.forEach((a) => {
                option = document.createElement("option");
                option.text = a.address;
                option.value = index;
                addressSelect.appendChild(option);
                index += 1;
              });
            }
            this.disableForm();
            if (data.logistic_train == "S") {
              this.showLogisticTrain();
            } else {
              this.hideLogisticTrain();
            }
            fields.find((f) => f.name == "business_name").value =
              data.business_name;
            fields.find((f) => f.name == "name").value = data.name;
            if (data.email) {
              fields.find((f) => f.name == "email").value = data.email;
              fields.find((f) => f.name == "email").required = false;
            } else {
              fields
                .find((f) => f.name == "email")
                .classList.remove("disabled");
              fields.find((f) => f.name == "email").required = true;
            }
            fields.find((f) => f.name == "commune").value = communeVal;
            fields.find((f) => f.name == "phone_number").value =
              data.phone_number;
            fields.find((f) => f.name == "address").value = data.street_address;
            //fields.find( f => f.name == 'commit').disabled = false
            if (fields.find((f) => f.name == "phone_number").value == "") {
              fields
                .find((f) => f.name == "phone_number")
                .classList.remove("disabled");
              fields.find((f) => f.name == "phone_number").required = true;
            }
          }
        });
    } else {
      this.disableForm();
    }
  }

  disableForm() {
    let fields = this.fieldTargets;
    fields.find((f) => f.name == "business_name").value = "";
    fields.find((f) => f.name == "name").value = "";
    fields.find((f) => f.name == "email").value = "";
    fields.find((f) => f.name == "phone_number").value = "";
    fields.find((f) => f.name == "address").value = "";
    fields.find((f) => f.name == "business_name").required = true;
    fields.find((f) => f.name == "name").required = true;
    fields.find((f) => f.name == "email").required = true;
    fields.find((f) => f.name == "commune").required = true;
    fields.find((f) => f.name == "phone_number").required = true;
    fields.find((f) => f.name == "address").required = true;
    fields.find((f) => f.name == "business_name").classList.add("disabled");
    fields.find((f) => f.name == "name").classList.add("disabled");
    fields.find((f) => f.name == "email").classList.add("disabled");
    fields.find((f) => f.name == "commune").classList.add("disabled");
    fields.find((f) => f.name == "phone_number").classList.add("disabled");
    fields.find((f) => f.name == "address").classList.add("disabled");
    //fields.find( f => f.name == 'commit').disabled = true
  }

  validateForm() {
    let fields = this.fieldTargets;
    let rut = fields.find((f) => f.name == "rut");
    let business_name = fields.find((f) => f.name == "business_name");
    let name = fields.find((f) => f.name == "name");
    let email = fields.find((f) => f.name == "email");
    let phone_number = fields.find((f) => f.name == "phone_number");
    let address = fields.find((f) => f.name == "address");
    let commune = fields.find((f) => f.name == "commune");
    let shipping_address = fields.find((f) => f.name == "shipping_address");
    let shipping_phone_number = fields.find((f) => f.name == "shipping_phone_number");
    let shipping_email = fields.find((f) => f.name == "shipping_email");
    let shipping_commune = fields.find((f) => f.name == "shipping_commune");
    let delivery_type = fields.find((f) => f.name == "delivery_type");
    let notification_email = fields.find((f) => f.name == "notification_mail");
    let notification_phone_number = fields.find((f) => f.name == "notification_phone");
    let shipping_address_value = shipping_address.value;
    let shipping_phone_number_value = shipping_phone_number.value;
    let shipping_email_value = shipping_email.value;
    let shipping_commune_value = shipping_commune.value;
    let delivery_type_value = delivery_type.value;
    let notification_email_value = notification_email.value;
    let notification_phone_number_value = notification_phone_number.value;
    let commune_value = commune.value;
    let address_value = address.value;
    let phone_number_value = phone_number.value;
    let email_value = email.value;
    let name_value = name.value;
    let business_name_value = business_name.value;
    let rut_value = rut.value;
    let validate = true;
    if (rut_value == "") {
      validate = false;
      rut.classList.add("is-invalid");
    
    } else {
      rut.classList.remove("is-invalid");
    }
    if (business_name_value == "") {
      validate = false;
      business_name.classList.add("is-invalid");
    
    } else {
      business_name.classList.remove("is-invalid");
    }
    if (name_value == "") {
      validate = false;
      name.classList.add("is-invalid");
      
    }else {
      name.classList.remove("is-invalid");
    }

    if (email_value == "") {
      validate = false;
      email.classList.add("is-invalid");
      
    } else {
      email.classList.remove("is-invalid");
    }

    if (phone_number_value == "") {
      validate = false;
      phone_number.classList.add("is-invalid");
      
    }else {
      phone_number.classList.remove("is-invalid");
    }

    if (address_value == "") {
      validate = false;
      address.classList.add("is-invalid");
      
    } else {
      address.classList.remove("is-invalid");
    }

    if (commune_value == "") {
      validate = false;
      commune.classList.add("is-invalid");
     
    } else{
      commune.classList.remove("is-invalid");
    }

    if(notification_email_value == "") {
      validate = false;
      notification_email.classList.add("is-invalid");
      
    } else {
      notification_email.classList.remove("is-invalid");
    }

    if(notification_phone_number_value == "") {
      validate = false;
      notification_phone_number.classList.add("is-invalid");
     
    } else {
      notification_phone_number.classList.remove("is-invalid");
    }

    if(delivery_type_value == "store-recall") {
      if(document.getElementById("pickup_store").value === ""){
        validate = false;
        document.getElementById("pickup_store").classList.add("is-invalid");
      } else {
        document.getElementById("pickup_store").classList.remove("is-invalid");
      }
    }

    if(delivery_type_value == "home-delivery") {
      if(document.getElementById("shipping").value === "") {
        validate = false;
        document.getElementById("shipping").classList.add("is-invalid");
      }else {
        document.getElementById("shipping").classList.remove("is-invalid");
      }
      if(document.getElementById("shipping").value === "new_address") {
        if(shipping_address_value == "") {
          validate = false;
          shipping_address.classList.add("is-invalid");
        } else {
          shipping_address.classList.remove("is-invalid");
        }
        if(shipping_phone_number_value == "") {
          validate = false;
          shipping_phone_number.classList.add("is-invalid");
        } else {
          shipping_phone_number.classList.remove("is-invalid");
        }
        if(shipping_email_value == "") {
          validate = false;
          shipping_email.classList.add("is-invalid");
        } else {
          shipping_email.classList.remove("is-invalid");
        }
        if(shipping_commune_value == "") {
          validate = false;
          shipping_commune.classList.add("is-invalid");
        } else {
          shipping_commune.classList.remove("is-invalid");
        }
      }
    }

    fields.forEach(field => {
      field.addEventListener('focus', function() {
        this.classList.remove("is-invalid");
      });

      document.getElementById("shipping").addEventListener('focus', function() {
        this.classList.remove("is-invalid");
      });

      document.getElementById("pickup_store").addEventListener('focus', function() {
        this.classList.remove("is-invalid");
      });
  });
    if(validate) {
      document.getElementById("cotizar_button").disabled = true;
      setTimeout(() => {
        document.getElementById("checkout_form").submit();
      }, 1000);
    }
  }

  warehouse() {
    this.checkPackagingPrice();
    this.fieldTargets.find((f) => f.name == "delivery_type").value =
      "store-recall";
    this.shippingButtonTarget.classList.remove("active");
    this.shippingButtonTarget.classList.add("deactive");
    this.warehouseButtonTarget.classList.remove("deactive");
    this.warehouseButtonTarget.classList.add("active");
    this.packagingTarget.classList.remove("d-none");
    this.pickupTarget.classList.remove("d-none");
    this.shippingTarget.classList.add("d-none");
    this.shippingAddressTarget.classList.add("d-none");
     document.getElementById("shipping").value = "";
    this.fieldTargets.find((f) => f.name == "shipping_address").value = ""
    this.fieldTargets.find((f) => f.name == "shipping_phone_number").value = ""
    this.fieldTargets.find((f) => f.name == "shipping_email").value = ""
    this.fieldTargets.find((f) => f.name == "shipping_commune").value = ""

  }

  shipping() {
    document.getElementById("pickup_store").value = "";
    this.fieldTargets.find((f) => f.name == "packaging").value = "packaging";
    this.fieldTargets.find((f) => f.name == "delivery_type").value =
      "home-delivery";
    this.warehouseButtonTarget.classList.remove("active");
    this.warehouseButtonTarget.classList.add("deactive");
    this.shippingButtonTarget.classList.remove("deactive");
    this.logisticTrainButtonTarget.classList.remove("active");
    this.logisticTrainButtonTarget.classList.add("active");
    this.shippingButtonTarget.classList.add("active");
    this.packagingTarget.classList.add("d-none");
    this.pickupTarget.classList.add("d-none");
    this.shippingTarget.classList.remove("d-none");

    let addressIndex = document.getElementById("shipping").value;
    if(addressIndex == "") {
      return
    }else{
    if (addressIndex == "new_address") {
      this.shippingAddressTarget.classList.remove("d-none");
    } else if (addressIndex == "billing_address") {
      this.fieldTargets.find((f) => f.name == "shipping_address").value = this.fieldTargets.find(
        (f) => f.name == "address"
      ).value;
      this.fieldTargets.find((f) => f.name == "shipping_phone_number").value = this.fieldTargets.find(
        (f) => f.name == "phone_number"
      ).value;
      this.fieldTargets.find((f) => f.name == "shipping_email").value = this.fieldTargets.find(
        (f) => f.name == "email"
      ).value;
      let communeSelect = this.fieldTargets.find((f) => f.name == "shipping_commune");
      let billingCommuneId = this.fieldTargets.find((f) => f.name == "commune").value;
      let communesArray = Array.prototype.slice.call(communeSelect.options);
      let index = communesArray.find((c) => c.value == billingCommuneId).index;
      communeSelect.selectedIndex = index;
      this.checkDeliveryPrice(communeSelect.value);
      this.checkRectificationPrice(communeSelect.value);
      this.shippingAddressTarget.classList.add("d-none");
    } else {
      let address = storedAddress[addressIndex];
      this.fieldTargets.find((f) => f.name == "shipping_address").value = address.address;
      this.fieldTargets.find((f) => f.name == "shipping_phone_number").value =
        address.phone_number;
      this.fieldTargets.find((f) => f.name == "shipping_email").value = address.email;
      let communeSelect = this.fieldTargets.find((f) => f.name == "shipping_commune");
      let billingCommune = address.commune;
      let communesArray = Array.prototype.slice.call(communeSelect.options);
      let index = communesArray.find((c) => c.text == billingCommune).index;
      communeSelect.selectedIndex = index;
      this.checkDeliveryPrice(communeSelect.value);
      this.checkRectificationPrice(communeSelect.value);
      this.shippingAddressTarget.classList.add("d-none");
    }
  }
  }

  logisticTrain() {
    this.fieldTargets.find((f) => f.name == "packaging").value = "packaging";
    this.fieldTargets.find((f) => f.name == "delivery_type").value =
      "logistic-train";
    // this.logisticTrainButtonTarget.classList.remove("btn-primary-outline");
    // this.logisticTrainButtonTarget.classList.add("btn-primary-no-upper");
    // this.warehouseButtonTarget.classList.remove("btn-primary-no-upper");
    // this.warehouseButtonTarget.classList.add("btn-primary-outline");
    // this.shippingButtonTarget.classList.remove("btn-primary-no-upper");
    // this.shippingButtonTarget.classList.add("btn-primary-outline");
    this.packagingTarget.classList.add("d-none");
    this.shippingTarget.classList.remove("d-none");
  }

  showLogisticTrain() {
    document.querySelector(".logistic-delivery-options").classList.remove("d-none");
    document.querySelector(".hideLogisticTrain").classList.add("d-none");
  }

  hideLogisticTrain() {
    if (document.querySelector(".logistic-delivery-options").classList.contains("d-none")) {
      return;
    }
    document.querySelector(".logistic-delivery-options").classList.add("d-none");
    document.querySelector(".hideLogisticTrain").classList.remove("d-none");
    document.querySelectorAll(".delivery-type").forEach((container) => {
      container.classList.replace("col-md-4", "col-md-6");
    });
  }

  shippingAddress(event) {
    let fields = this.fieldTargets;
    if (event.target.value == "new_address") {
      this.shippingAddressTarget.classList.remove("d-none");
    } else if (event.target.value == "billing_address") {
      fields.find((f) => f.name == "shipping_address").value = fields.find(
        (f) => f.name == "address"
      ).value;
      fields.find((f) => f.name == "shipping_phone_number").value = fields.find(
        (f) => f.name == "phone_number"
      ).value;
      fields.find((f) => f.name == "shipping_email").value = fields.find(
        (f) => f.name == "email"
      ).value;
      let communeSelect = fields.find((f) => f.name == "shipping_commune");
      let billingCommuneId = fields.find((f) => f.name == "commune").value;
      let communesArray = Array.prototype.slice.call(communeSelect.options);
      let index = communesArray.find((c) => c.value == billingCommuneId).index;
      communeSelect.selectedIndex = index;
      this.checkDeliveryPrice(communeSelect.value);
      this.checkRectificationPrice(communeSelect.value);
      this.shippingAddressTarget.classList.add("d-none");
    } else {
      let addressIndex = event.target.value;
      let addr = storedAddress[addressIndex];
      fields.find((f) => f.name == "shipping_address").value = addr.address;
      fields.find((f) => f.name == "shipping_phone_number").value =
        addr.phone_number;
      fields.find((f) => f.name == "shipping_email").value = addr.email;
      let communeSelect = fields.find((f) => f.name == "shipping_commune");
      let billingCommune = addr.commune;
      let communesArray = Array.prototype.slice.call(communeSelect.options);
      let index = communesArray.find((c) => c.text == billingCommune).index;
      communeSelect.selectedIndex = index;
      this.checkDeliveryPrice(communeSelect.value);
      this.checkRectificationPrice(communeSelect.value);
      this.shippingAddressTarget.classList.add("d-none");
    }
  }

  checkDeliveryPrice(commune_id) {
    let cotizar_button = document.getElementById("cotizar_button");
    cotizar_button.disabled = true;
    this.deliveryPriceTarget.innerHTML = "Cargando...";
    let tax_ref = this.fieldTargets.find((f) => f.name == "rut");
    let url = `${window.location.href}/delivery_price?tax_ref=${tax_ref.value}&commune_id=${commune_id}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        cotizar_button.disabled = false;
        this.deliveryPriceTarget.dataset.value = data.net_item_total;
        let shippingPrice = 0;
        if (
          this.fieldTargets.find((f) => f.name == "delivery_type").value !=
          "logistic-train"
        ) {
          shippingPrice = data.net_unit_price;
        }
        this.deliveryPriceTarget.innerHTML = `1 x ${this.currencyFormat(
          shippingPrice
        )}`;
        this.calculateTotal();
      });
  }

  checkRectificationPrice(commune_id) {
    let tax_ref = this.fieldTargets.find((f) => f.name == "rut");
    let commune = commune_id == undefined ? "" : commune_id;
    let url = `${window.location.href}/rectification_price?tax_ref=${tax_ref.value}&commune_id=${commune}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data == undefined) {
          return;
        }
        this.rectificationPriceTarget.dataset.value = data.net_item_total;
        this.rectificationPriceTarget.innerHTML = `${
          this.rectificationPriceTarget.dataset.quantity
        } x ${this.currencyFormat(data.net_unit_price)}`;
        this.calculateTotal();
      });
  }

  checkCartPrice() {
    let tax_ref = this.fieldTargets.find((f) => f.name == "rut");
    let url = `${window.location.href}/update_cart_price?tax_ref=${tax_ref.value}`;
    let item_price = this.itemTotalTargets;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        data.cart_quotes.forEach((item) => {
          let cartItem = item_price.find((f) => f.dataset.id == item.id);
          let cartValue = item.total_cents;
          cartItem.dataset.value = cartValue;
          cartItem.innerHTML = `${this.currencyFormat(cartValue)}`;
        });
        this.subtotalTarget.dataset.originalValue = data.subtotal;
        this.calculateTotal();
      });
  }

  checkPackagingPrice() {
    let cotizar_button = document.getElementById("cotizar_button");
    cotizar_button.disabled = true;
    this.packagingPriceTarget.innerHTML = "Cargando..."
    this.deliveryPriceTarget.innerHTML = "Cargando..."
    let tax_ref = this.fieldTargets.find((f) => f.name == "rut");
    let url = `${window.location.href}/packaging_price?tax_ref=${tax_ref.value}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        cotizar_button.disabled = false;
        this.deliveryPriceTarget.dataset.value = 0;
        this.deliveryPriceTarget.innerHTML = `1 x ${this.currencyFormat(0)}`;
        this.packagingPriceTarget.dataset.value = data.net_item_total;
        this.packagingPriceTarget.innerHTML = `${this.calculateDeliveryQuantity()} x ${this.currencyFormat(
          data.net_unit_price
        )}`;
        this.calculateTotal();
      });
  }

  calculateDeliveryQuantity() {
    let delivery_quantity = 0;
    JSON.parse(this.deliveryPriceTarget.dataset.cart_item).forEach((item) => {
      if (parseInt(item.accordion.height) > 0) {
        delivery_quantity += parseInt(item.space.width) / 1000.0;
      } else {
        delivery_quantity += parseInt(item.door.quantity);
      }
    });
    return delivery_quantity;
  }

  removePackagingPrice() {
    this.packagingPriceTarget.dataset.value = 0;
    this.packagingPriceTarget.innerHTML = `${this.calculateDeliveryQuantity()} x ${this.currencyFormat(
      0
    )}`;
    this.calculateTotal();
  }

  calculateTotal() {
    let delivery,
      packaging,
      rectification,
      subtotal,
      taxes,
      total = 0;
    rectification = parseInt(this.rectificationPriceTarget.dataset.value);
    delivery = parseInt(this.deliveryPriceTarget.dataset.value);
    packaging = parseInt(this.packagingPriceTarget.dataset.value);
    subtotal = parseInt(this.subtotalTarget.dataset.originalValue);
    subtotal = subtotal + delivery + packaging + rectification;
    taxes = Math.round(subtotal * 0.19);
    total = subtotal + taxes;
    this.subtotalTarget.dataset.value = subtotal;
    this.taxesTarget.dataset.value = taxes;
    this.totalTarget.dataset.value = total;
    this.subtotalTarget.innerHTML = this.currencyFormat(subtotal);
    this.taxesTarget.innerHTML = this.currencyFormat(taxes);
    this.totalTarget.innerHTML = this.currencyFormat(total);
  }

  packagingPrice(event) {
    if (event.target.value == "packaging") {
      this.checkPackagingPrice();
      this.checkRectificationPrice();
    } else {
      this.removePackagingPrice();
    }
  }

  newAddressPrice(event) {
    this.checkDeliveryPrice(event.target.value);
    this.checkRectificationPrice(event.target.value);
  }

  validatePhone() {
    let phone = document.querySelector("#phone_number").value;
    let out = "";
    let filter = /^[0-9]{1,12}$/;
    if (phone.match(filter) != null) {
      out += phone.match(filter);
      document.querySelector("#phone_number").value = out;
      return out;
    }
    out = phone.replaceAll(phone[phone.length - 1], "");
    document.querySelector("#phone_number").value = out;
    return out;
  }

  currencyFormat(num) {
    return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }

  // When a change is detected in pickup_store selection
  // Gets the value of actual selection and add it to subtotal.
  getPickUpPrice(event) {
    if (event.target.value == "") {
      this.deliveryPriceTarget.dataset.value = 0;
      this.deliveryPriceTarget.innerHTML = `${this.calculateDeliveryQuantity()} x ${this.currencyFormat(
        parseInt(0)
      )}`;
    } else {
      fetch(`/api/v1/stores/${event.target.value}/information`)
        .then((response) => response.json())
        .then((data) => {
          this.deliveryPriceTarget.dataset.value = data.closet_price;
          this.deliveryPriceTarget.innerHTML = `${this.calculateDeliveryQuantity()} x ${this.currencyFormat(
            parseInt(data.closet_price)
          )}`;
        });
    }
    this.calculateTotal();
  }
}
